<template>
  <b-row>

    <h2 class="pl-1">{{ $t('side_bar.user') }}</h2>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between col-12">
      <b-col
        md="4"
        class="my-1 px-0"
      >
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="$t('reusable.search_placeholder')"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                {{ $t('reusable.clear_btn') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <div
        class="my-1 float-right"
      >
        <modal-button
          ripple="rgba(113, 102, 240, 0.15)"
          size="xl"
          name="modal-main"
          :modalButton="$t('reusable.modal_add_btn')"
          @btn-emit="createNewUser"
          @open-emit="clearUserData"
          :modal-title="$t('users_table.user_modal_title')"
          variant="success"
        >
          <template v-slot:button @click="createNewUser">{{ $t('reusable.modal_add_btn') }}</template>

          <template v-slot:modal-body>
            <div class="d-flex flex-wrap">

              <!--    REGIONS     -->
              <b-col cols="6" class="mb-1">
                <infinity-scroll-select
                  :value="regions"
                  v-model="creatingRegion.regions"
                  apiUrl="regions.fetchRegion"
                  :multiple="true"
                  :label="$t('users_table.choose_region')"
                />
              </b-col>

              <!--    CITIES      -->
              <b-col cols="6" class="mb-1">
                <infinity-scroll-select
                  :value="cities"
                  v-model="creatingCity.cities"
                  :multiple="true"
                  apiUrl="regions.fetchCity"
                  :label="$t('users_table.choose_city')"
                />
              </b-col>

              <!--    USERNAME    -->
              <b-col cols="6">
                <b-form-group>
                  <label>{{ $t('users_table.user_modal_username') }}</label>
                  <b-form-input
                    v-model="username"
                    size="lg"
                    :placeholder="$t('users_table.user_modal_username')"
                  />
                </b-form-group>
              </b-col>

              <!--    EMAIL    -->
              <b-col cols="6">
                <label>{{ $t('users_table.user_modal_email') }}</label>
                <b-form-group>
                  <b-form-input
                    v-model="newEmail"
                    autocomplete="off"
                    size="lg"
                    :placeholder="$t('users_table.user_modal_email')"
                  />
                </b-form-group>
              </b-col>

              <!--    NEW_PASSWORD    -->
              <b-col cols="6">
                <label>{{ $t('users_table.user_modal_new_password') }}</label>
                <b-form-group>
                  <!-- password -->
                  <b-input-group>
                    <b-form-input
                      v-model="newPassword"
                      size="lg"
                      autocomplete="off"
                      :type="passwordFieldType"
                      :placeholder="$t('users_table.user_modal_new_password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>


              </b-col>

              <!--    FULL_NAME    -->
              <b-col cols="6">
                <label>{{ $t('users_table.user_modal_full_name') }}</label>
                <b-form-group>
                  <b-form-input
                    v-model="full_name"
                    size="lg"
                    :placeholder="$t('users_table.user_modal_full_name')"
                  />
                </b-form-group>
              </b-col>

              <!--    PHONE NUMBER    -->
              <b-col cols="6">
                <label>{{ $t('users_table.user_modal_phone') }}</label>
                <b-form-group>
                  <b-form-input
                    v-model="phoneNumber"
                    size="lg"
                    :placeholder="$t('users_table.user_modal_phone')"
                  />
                </b-form-group>
              </b-col>

              <!--    ROLE    -->
              <b-col cols="6">
                <label>{{ $t('task_table.choose_task_type') }}</label>
                <v-select
                  v-model="userOption"
                  :options="userRoleOption"
                >
                </v-select>
              </b-col>


            </div>
          </template>
        </modal-button>

      </div>
    </div>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        sort-icon-left
        :busy="isBusy"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"

        @filtered="onFiltered"
      >
        <template v-slot:head(is_deleted)="data">
          <b-form-select
            size="md"
            v-model="status"
            class="w-100"
          >
            <template #first>
              <option
                selected
                :value="null"
              >
                Выберите статус
              </option>
            </template>
            <option
              v-for="(status, index) in statuses"
              :key="index"
              :value="status"
            >
              {{ status }}
            </option>
          </b-form-select>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">{{ $t('reusable.isBusy') }}</strong>
          </div>
        </template>

        <template #cell(role)="data">
          <span v-if="data.item.role == 'agent'">{{ $t('reusable.agent') }}</span>
          <span v-else>{{ $t('reusable.admin') }}</span>
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">

            <b-dropdown
              no-caret
              id="table-action-dropdown"
              variant="link"
              size="md"
            >
              <template
                #button-content
                class="p-0"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="p-0"
                />
              </template>

              <!--   PREVIEW BUTTON   -->
              <b-dropdown-item
                class="px-0"
              >
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="xl"
                  :name="`modal-preview-${data.item.id}`"
                  :backdrop-close="true"
                  :hide-footer="true"
                  :modalButton="$t('region_table.country_edit_modal_title')"
                  :id="data.item.id"
                  :modal-title="$t('reusable.preview_btn')"
                  :disableButton="!editAllow"
                  variant="flat-primary"
                  @open-emit="getUserById(data.item.id)"
                  @btn-emit="updateUser(data.item.id, data.item)"
                >
                  <template
                    v-slot:button
                    style="width: 100% !important;"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="EyeIcon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.preview_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <!--                    <div v-show="Object.keys(region)" class="d-flex flex-wrap">-->
                    <div class="d-flex flex-wrap">

                      <!--    REGIONS     -->
                      <b-col class="mb-1" cols="6">
                        <infinity-scroll-select
                          :value="regions"
                          v-model="creatingRegion.regions"
                          :multiple="true"
                          :disabled="!editAllow"
                          apiUrl="regions.fetchRegion"
                          :label="$t('users_table.choose_region')"
                        />
                      </b-col>

                      <!--    CITIES      -->
                      <b-col class="mb-1" cols="6">
                        <infinity-scroll-select
                          :value="cities"
                          v-model="creatingCity.cities"
                          :multiple="true"
                          :disabled="!editAllow"
                          apiUrl="regions.fetchCity"
                          :label="$t('users_table.choose_city')"
                        />
                      </b-col>

                      <!--    USERNAME    -->
                      <b-col cols="6">
                        <label>{{ $t('users_table.user_modal_username') }}</label>
                        <b-form-group>
                          <b-form-input
                            v-model="username"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('users_table.user_modal_username')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--    EMAIL    -->
                      <b-col cols="6">
                        <label>{{ $t('users_table.user_modal_email') }}</label>
                        <b-form-group>
                          <b-form-input
                            v-model="email"
                            :disabled="!editAllow"
                            size="lg"
                            :placeholder="$t('users_table.user_modal_email')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--    NEW_PASSWORD    -->
                      <b-col cols="6">
                        <label>{{ $t('users_table.user_modal_new_password') }}</label>
                        <b-form-group>
                          <!-- password -->
                          <b-input-group>
                            <b-form-input
                              v-model="newPassword"
                              size="lg"
                              :disabled="!editAllow"
                              :type="passwordFieldType"
                              :placeholder="$t('users_table.user_modal_new_password')"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                :icon="passwordToggleIcon"
                                class="cursor-pointer"
                                @click="togglePasswordVisibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>


                      </b-col>

                      <!--    FULL_NAME    -->
                      <b-col cols="6">
                        <label>{{ $t('users_table.user_modal_full_name') }}</label>
                        <b-form-group>
                          <b-form-input
                            v-model="full_name"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('users_table.user_modal_full_name')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--    PHONE NUMBER    -->
                      <b-col cols="6">
                        <label>{{ $t('users_table.user_modal_phone') }}</label>
                        <b-form-group>
                          <b-form-input
                            v-model="phoneNumber"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('users_table.user_modal_phone')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--    ROLE    -->
                      <b-col cols="6">
                        <label>{{ $t('task_table.choose_task_type') }}</label>
                        <v-select
                          v-model="userOption"
                          :options="userRoleOption"
                          :disabled="!editAllow"
                        >
                        </v-select>
                      </b-col>

                      <b-col cols="6">
                        <b-form-group
                          :label="$t('region_table.created_at')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="createdAt"
                            id="defaultLabel"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('region_table.created_at')"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="6">
                        <b-form-group
                          :label="$t('region_table.updated_at')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="updatedAt"
                            id="defaultLabel"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('region_table.updated_at')"
                          />
                        </b-form-group>
                      </b-col>

                    </div>

                  </template>


                </modal-button>
              </b-dropdown-item>

              <!--  EDIT BUTTON  -->
              <b-dropdown-item
                class="px-0"
              >
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="xl"
                  :backdrop-close="true"
                  :name="`modal-edit-${data.item.id}`"
                  :modalButton="$t('reusable.save_btn')"
                  :id="data.item.id"
                  :modal-title="$t('reusable.modal_edit_btn')"
                  variant="flat-warning"
                  @open-emit="getUserById(data.item.id)"
                  @btn-emit="updateUser(data.item.id, data.item)"
                >
                  <template
                    v-slot:button
                    style="width: 100% !important;"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Edit2Icon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.modal_edit_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="d-flex flex-wrap">

                      <!--    REGIONS     -->
                      <b-col class="mb-1" cols="6">
                        <infinity-scroll-select
                          :value="regions"
                          :multiple="true"
                          v-model="creatingRegion.regions"
                          apiUrl="regions.fetchRegion"
                          :label="$t('users_table.choose_region')"
                        />
                      </b-col>

                      <!--    CITIES      -->
                      <b-col class="mb-1" cols="6">
                        <infinity-scroll-select
                          :value="cities"
                          v-model="creatingCity.cities"
                          :multiple="true"
                          apiUrl="regions.fetchCity"
                          :label="$t('users_table.choose_city')"
                        />
                      </b-col>

                      <!--    USERNAME    -->
                      <b-col cols="6">
                        <label>{{ $t('users_table.user_modal_username') }}</label>
                        <b-form-group>
                          <b-form-input
                            v-model="username"
                            size="lg"
                            :placeholder="$t('users_table.user_modal_username')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--    EMAIL    -->
                      <b-col cols="6">
                        <label>{{ $t('users_table.user_modal_email') }}</label>
                        <b-form-group>
                          <b-form-input
                            v-model="email"
                            size="lg"
                            :placeholder="$t('users_table.user_modal_email')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--    NEW_PASSWORD    -->
                      <b-col cols="6">
                        <label>{{ $t('users_table.user_modal_new_password') }}</label>
                        <b-form-group>
                          <!-- password -->
                          <b-input-group>
                            <b-form-input
                              v-model="newPassword"
                              size="lg"
                              :type="passwordFieldType"
                              placeholder="Your Password"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                :icon="passwordToggleIcon"
                                class="cursor-pointer"
                                @click="togglePasswordVisibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>


                      </b-col>

                      <!--    FULL_NAME    -->
                      <b-col cols="6">
                        <label>{{ $t('users_table.user_modal_full_name') }}</label>
                        <b-form-group>
                          <b-form-input
                            v-model="full_name"
                            size="lg"
                            :placeholder="$t('users_table.user_modal_full_name')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--    PHONE NUMBER    -->
                      <b-col cols="6">
                        <label>{{ $t('users_table.user_modal_phone') }}</label>
                        <b-form-group>
                          <b-form-input
                            v-model="phoneNumber"
                            size="lg"
                            :placeholder="$t('users_table.user_modal_phone')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--    ROLE    -->
                      <b-col cols="6" class="mb-1">
                        <label>{{ $t('task_table.choose_task_type') }}</label>
                        <v-select
                          v-model="userOption"
                          :options="userRoleOption"
                        >
                        </v-select>
                      </b-col>

                    </div>

                  </template>

                </modal-button>
              </b-dropdown-item>

              <!--  DELETE BUTTON  -->
              <b-dropdown-item>
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="sm"
                  :name="`modal-delete-${data.item.id}`"
                  :modalButton="$t('region_table.country_delete_modal_title')"
                  :modal-title="$t('region_table.country_delete_modal_title')"
                  variant="flat-danger"
                  :id="data.item.id"
                  @btn-emit="deleteUser"
                >
                  <template v-slot:button class="w-100">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Trash2Icon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.modal_delete_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="my-2">
                      {{ $t('users_table.user_delete_modal_body') }}
                    </div>
                  </template>

                </modal-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </template>

      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
      cols="12"
    >
      <b-pagination
        v-if="showPagination"
        v-model="pagination.current"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BFormCheckbox,
  BDropdownItem,
  BSpinner
} from 'bootstrap-vue'
import ModalButton from '@/views/ui/modals/ModalButton.vue'
import api from '@/services/api'
import infinityScrollSelect from '@/views/ui/infinity-scroll/InfinityScrollSelect'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'

export default {
  name: 'User',
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    ModalButton,
    infinityScrollSelect,
    vSelect
  },
  mixins: [togglePasswordVisibility],
  data() {

    return {
      createdAt: '',
      updatedAt: '',
      userRoleOption: [],
      userOption: {},
      regions: [],
      creatingRegion: {
        regions: null,
      },
      cities: [],
      creatingCity: {
        cities: null
      },
      editAllow: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      status: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      statuses: [true, false],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'full_name',
          label: this.$t('users_table.full_name'),
        },
        {
          key: 'username',
          label: this.$t('users_table.username'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$t('users_table.email'),
          sortable: true,
        },
        {
          key: 'phone',
          label: this.$t('users_table.phone'),
        },
        {
          key: 'role',
          label: this.$t('users_table.role'),
        },
        {
          key: 'created_at',
          label: this.$t('region_table.created_at'),
        },
        {
          key: 'updated_at',
          label: this.$t('region_table.updated_at'),
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      variant: '',
      items: [],
      username: '',
      email: '',
      password: '',
      newPassword: '',
      newEmail: '',
      full_name: '',
      role: '',
      phoneNumber: '',
      passwordFieldType: 'password',
      isBusy: false,
      pagination: {
        current: 1,
        total: 1,
        per_page: 5
      }
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        this.pagination.current = query.page
        this.fetchUserList()
      },
      deep: true,
      immediate: true
    },
    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    }
  },

  computed: {
    rows() {
      return this.items.length
    },
    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },

    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  created() {
    this.getUserRole()
  },

  methods: {

    clearUserData() {
      this.username = ''
      this.email = ''
      this.password = ''
      this.newPassword = ''
      this.newEmail = ''
      this.full_name = ''
      this.userOption = null
      this.phoneNumber = ''
      this.creatingCity.cities = []
      this.creatingRegion.regions = []
    },

    replaceRouter(query) {
      this.$router.replace({ query })
        .catch(() => {
        })
    },

    async fetchUserList() {
      this.isBusy = true
      const data = {
        page: this.$route.query.page
      }
      await api.users.fetchUser(data)
        .then(res => {
          this.items = res.data.data
          this.pagination.per_page = res.data.meta.per_page
          this.pagination.total = res.data.meta.total
          this.pagination.current = res.data.meta.current_page
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    async getUserById(id) {
      const data = {
        with: {
          0: 'regions',
          1: 'cities'
        }
      }
      this.editAllow = false
      await api.users.fetchOneUser(id, data)
        .then(res => {
          this.createdAt = res.data.created_at
          this.updatedAt = res.data.updated_at
          this.email = res.data.email
          this.newPassword = res.data.password
          this.username = res.data.username
          this.full_name = res.data.full_name
          this.phoneNumber = res.data.phone

          this.userRoleOption.map(item => {
            if (item.value === res.data.role) {
              this.userOption = item
            }
          })

          // this.userOption = res.data.role
          this.creatingRegion.regions = res.data.regions
          this.creatingCity.cities = res.data.cities
        })
        .catch((error) => {
          console.error(error)
        })
    },

    deleteUser(id) {
      api.users.deleteUser(id)
        .then(res => {
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchUserList()
          this.username = ''
          this.email = ''
          this.new_password = ''
          this.full_name = ''
          this.role = ''
          this.phoneNumber = ''
          this.cities = ''
          this.regions = ''
        })
    },

    createNewUser() {
      const data = {
        username: this.username,
        email: this.newEmail,
        new_password: this.newPassword,
        full_name: this.full_name,
        role: this.userOption.value,
        phone: this.phoneNumber,
        regions: this.creatingRegion.regions.map(item => item.id),
        cities: this.creatingCity.cities.map(item => item.id)
      }

      api.users.createUser(data)
        .then(res => {
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchUserList()
        })
    },

    updateUser(id, data) {
      const body = {
        username: this.username,
        email: this.email,
        new_password: this.newPassword,
        full_name: this.full_name,
        role: this.userOption.value,
        phone: this.phoneNumber,
        regions: this.creatingRegion.regions.map(item => item.id),
        cities: this.creatingCity.cities.map(item => item.id)
      }
      api.users.updateUser(id, body)
        .then(res => {
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchUserList()
          this.username = ''
          this.email = ''
          this.newPassword = ''
          this.full_name = ''
          this.userOption = null
          this.phoneNumber = ''
          this.cities = ''
          this.regions = ''
        })
    },

    getUserRole() {
      api.enums.fetchUserRole()
        .then(res => {
          if (!(this.userRoleOption.length)) {
            for (let [key, value] of Object.entries(res.data.roles)) {
              this.userRoleOption.push({
                'value': key, ...value
              })
            }
          }

        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          // this.clearData()
        })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss">


[dir] .dropdown-item {
  padding: 0;
}

[dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::before {
  left: 0;
  top: 0;
  //position: static;
  margin-right: 12px;
}

[dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::after {
  left: 0;
  top: 0;
  margin-right: 12px;
}

.custom-checkbox .custom-control-label, .custom-radio .custom-control-label {
  display: flex;
  align-items: center;
}

::v-deep .vs__dropdown-menu {
  max-height: 100px !important;
}

.list-group-wrapper {
  position: relative;
}

.list-group {
  overflow: auto;
  height: 100px;
  border: 2px solid #dce4ec;
  border-radius: 5px;
}

.list-group-item {
  margin-top: 1px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid #dce4ec;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

</style>
